/* eslint brace-style: ["error", "stroustrup"] */
import { useEffect, useRef, useState } from 'react';
import './SignUp.css';
import { Button, Form } from 'semantic-ui-react';
import axios from 'axios/index';
import { useTranslation } from 'react-i18next';
import * as loglevel from 'loglevel';
import { Box, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SupportRequest from '../../reusable/SupportRequest';
import { SpamBotDetection } from '../../spambot_detection/SpamBotDetection';
import env from '../../../env/env';
import MicrosoftLoginButton from '../../reusable/MicrosoftLoginButton';
import TermsOfUseAgreement from '../../reusable/TermsOfUseAgreement';
import PasswordInput from '../../reusable/PasswordInput';

const log = loglevel.getLogger(`${__dirname}/${__filename}`);
log.setLevel(env.REACT_APP_GI_ENV === 'development' ? loglevel.levels.WARN : loglevel.levels.WARN);

export default function SignUp() {
  const [t] = useTranslation();
  const [messages, setMessages] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [passwordValidationErrors, setPasswordValidationErrors] = useState([]);
  const [name, setName] = useState(null);
  const [hasSubmittedOnce, setHasSubmittedOnce] = useState(false);
  const sourceRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!password.length) {
      setPasswordValidationErrors([]);
      return;
    }

    (async () => {
      const endpoint = `${env.API_GATEWAY_BASE}/api/password-isvalid?part=${encodeURIComponent(password)}`;
      const { CancelToken } = axios;
      const source = CancelToken.source();
      if (sourceRef.current) {
        sourceRef.current.cancel('Cancelled previous request');
      }
      sourceRef.current = source;
      await axios({
        url: endpoint,
        cancelToken: source.token,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then((response) => {
          if (response.data.success) {
            setPasswordValidationErrors([]);
          }
          else {
            setPasswordValidationErrors([
              response.data.message,
            ]);
          }

          return response;
        });
    })();
  }, [password]);

  const needsRedirectToEmailResend = (response) => {
    const msg = response.data.message;
    const { success } = response.data;

    return (response.status === 200
      && success === false
      && msg.includes('is already taken'));
  };

  const submit = async () => {
    if (password !== passwordRepeat) {
      setErrors([t('sign_up:passwords_does_not_match_error_message')]);
      return undefined;
    }

    const endpoint = `${env.API_GATEWAY_BASE}/api/register`;
    return axios({
      method: 'post',
      url: endpoint,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      data: JSON.stringify({
        email,
        password,
        passwordRepeat,
        toc: true,
      }),
    })
      .then((response) => {
        if (needsRedirectToEmailResend(response)) {
          navigate('/user/signup-resend/');
        }
        else if (response?.data?.success === false) {
          setErrors((p) => p.concat([
            `${response?.data?.message}`,
          ]));
          setPassword('');
          setPasswordRepeat('');
        }
        else if (response?.data?.success === true) {
          setMessages((p) => p.concat([
            t('sign_up:message-elaborating-ok'),
            t('sign_up:message-confirm-link'),
          ]));
          setIsLoading(false);
          setIsSuccess(true);
        }
        return response;
      })
      .catch((e) => {
        const { response } = e;
        if (response.status === 422) {
          setErrors((p) => p.concat([
            t('sign_up:message-check-form'),
          ]));
        }
        else {
          setErrors((p) => p.concat([
            `${e.message}`,
          ]));
        }
        setIsLoading(false);
        setPassword('');
        setPasswordRepeat('');
      });
  };

  return (
    <div className="SignUp">
      <div className="signup-form">
        <div className="heading">
          <img
            className="image"
            alt=""
            src="https://geoimpactstorage.blob.core.windows.net/public/logo/sep/Logo-SEP-web.png"
          />
          <h1>{t('sign_up:label-register')}</h1>
        </div>
        <Form loading={isLoading}>
          <SpamBotDetection
            onChange={(e) => {
              setName(e.target.value);
            }}
            id="name"
          />
          <Form.Field
            error={(
              hasSubmittedOnce && email.length <= 0
            )}
            className={`isSuccess-${isSuccess}`}
          >
            <label htmlFor="email">{t('sign_up:label-email')}</label>
            <input
              id="email"
              placeholder={t('sign_up:label-email')}
              type="email"
              autoComplete="email"
              onChange={(event) => {
                const val = event.target.value;
                setEmail(val);
              }}
            />
          </Form.Field>
          <Form.Field
            error={(
              hasSubmittedOnce && password.length <= 0
            )}
            className={`isSuccess-${isSuccess}`}
          >
            <label htmlFor="password">{t('sign_up:label-password')}</label>
            <PasswordInput
              id="password"
              placeholder={t('sign_up:label-password')}
              value={password}
              onChange={(event) => {
                const val = event.target.value;
                if (!val) setPasswordValidationErrors([]);
                setPassword(val);
              }}
            />
          </Form.Field>
          <div className={`password-hints isSuccess-${isSuccess}`}>
            {(() => {
              if (passwordValidationErrors.length > 0) {
                const errorsString = passwordValidationErrors[passwordValidationErrors.length - 1];
                const validationErrors = errorsString.split('|');
                const items = [];
                validationErrors.forEach((e, i) => {
                  items.push((
                    <li key={JSON.stringify({ e, i })}>{e}</li>
                  ));
                });
                return <ul>{items}</ul>;
              }
              return '';
            })()}
          </div>
          <Form.Field
            error={(
              hasSubmittedOnce && passwordRepeat.length <= 0
            )}
            className={`isSuccess-${isSuccess}`}
          >
            <label htmlFor="passwordRepeat">{t('sign_up:label-password-repeat')}</label>
            <PasswordInput
              id="passwordRepeat"
              placeholder={t('sign_up:label-password-repeat')}
              value={passwordRepeat}
              onChange={(event) => {
                const val = event.target.value;
                setPasswordRepeat(val);
              }}
            />
          </Form.Field>
          {messages.length > 0 && errors.length === 0 && (
            <h5
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{
                __html: messages[messages.length - 1],
              }}
            />
          )}
          {errors.length > 0 && (
            <h5
              style={{ color: 'red' }}
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{
                __html: errors[errors.length - 1],
              }}
            />
          )}
          <Stack textAlign="center">
            <div className={`column-flex resend-button isSuccess-${isSuccess}`}>
              <Button
                primary
                type="submit"
                disabled={!(
                  password.length > 0
                  && passwordRepeat.length > 0
                  && email.length > 0
                  && name === null
                )}
                onClick={async () => {
                  setHasSubmittedOnce(true);
                  await submit();
                }}
              >
                {t('sign_up:label-register')}
              </Button>
            </div>
            <Box className={`column-flex isSuccess-${isSuccess}`}>
              <Typography fontWeight="800">{t('sign_in:or-continue-with')}</Typography>
            </Box>
            <div className={`column-flex resend-button microsoft isSuccess-${isSuccess}`}>
              <MicrosoftLoginButton
                buttonText="signup"
              />
            </div>
            <Box className="column-flex">
              <TermsOfUseAgreement />
            </Box>
          </Stack>
        </Form>
        <div className="footer">
          <SupportRequest />
        </div>
      </div>
    </div>
  );
}
